(function () {
    'use strict';

    angular
        .module('collaterateApp')
        .controller('NotificationsController', NotificationsController);

    NotificationsController.$inject = ['$scope', '$interval', 'OrderLogService', 'csrService', 'growlNotifications', 'UtilService', 'followedUsers', 'InternalNotificationService', 'secureEntities', 'CollaterateSecurityService', 'permissions', 'localStorageService'];

    function NotificationsController ($scope, $interval, OrderLogService, csrService, growlNotifications, UtilService, followedUsers, InternalNotificationService, secureEntities, CollaterateSecurityService, permissions, localStorageService) {
        // local vars
        var vm = this;
        var notificationCountTimer = null; // holds timer that gets notification count every {{notificationCountPollingInterval}} ms
        var notificationCountPollingInterval = 120000; // how frequently to refresh the notification count

        // public properties
        vm.userId = $scope.userId;
        vm.orderLogEntries = [];
        vm.internalNotifications = [];
        vm.showPanel = false;
        vm.watchedUsers = [];
        vm.count = null;
        vm.loading = false;
        vm.filter = localStorageService.get(localStorageService.keys.notifications_selectedVisibility) || 'all';
        vm.filterOptions = [
            {
                value: 'all',
                label: 'Show all notifications'
            }, {
                value: 'internal',
                label: 'Show internal notifications only'
            }, {
                value: 'external',
                label: 'Show external notifications only'
            }
        ];

        // public methods
        vm.refreshNotifications = refreshNotifications;
        vm.searchForCSRs = searchForCSRs;
        vm.toggleNotifications = toggleNotifications;
        vm.close = close;
        vm.watchUser = watchUser;
        vm.unwatchUser = unwatchUser;
        vm.respondToInternalNotification = respondToInternalNotification;
        vm.respond = respond;
        vm.dismissOrderLogEntry = dismissOrderLogEntry;
        vm.openOrderLogEntry = openOrderLogEntry;
        vm.onFilterChange = function (value) {
            localStorageService.set(localStorageService.keys.notifications_selectedVisibility, value);
        };

        vm.dismissInternalNotification = dismissInternalNotification;
        vm.reopenInternalNotification = reopenInternalNotification;

        vm.secureEntities = secureEntities;

        var unwatch = $scope.$watch(watchSecurityService, handleSecurityChange);

        return;

        function watchSecurityService () {
            return CollaterateSecurityService.lastUpdated();
        }

        function handleSecurityChange (newVal, oldVal) {
            if (!newVal) {
                return;
            }

            init();
            unwatch();
        }

        function init () {
            getNotificationCount(vm.userId);
            startNotificationCountPoll();

            loadWatchedUsers(vm.userId);
        }

        function loadWatchedUsers (userId) {
            return followedUsers
                .getList(userId)
                .then(setFollowedUsers);

            function setFollowedUsers (response) {
                vm.watchedUsers = response || [];
                return response;
            }
        }

        function getNotificationCount (userId) {
            return followedUsers
                .getUserIdsCSVList(userId)
                .then(getCsrNotificationCount)
                .then(setCsrNotificationCount)
                .then(getInternalNotificationCount)
                .then(setInternalNotificationCount)
                .then(setTotalNotificationCount)
                .catch(stopNotificationCountPoll);

            function getCsrNotificationCount (userIdsCSVList) {
                return CollaterateSecurityService.hasPermission(secureEntities.ORDER_STATUS, permissions.READ)
                    ? OrderLogService.getOrderLogCountForCsrUserIds(userIdsCSVList)
                    : 0;
            }

            function setCsrNotificationCount (response) {
                return vm.csrNotificationCount = response && response.data
                    ? response.data.result
                    : response;
            }

            function getInternalNotificationCount () {
                var userIds = vm.watchedUsers.map(function (user) {
                    return user.id;
                });
                userIds.push(vm.userId);
                return CollaterateSecurityService.hasPermission(secureEntities.REST_INTERNAL_NOTIFICATIONS, permissions.READ)
                    ? InternalNotificationService.getNotificationCountForRecipientIds(userIds)
                    : 0;
            }

            function setInternalNotificationCount (response) {
                return vm.internalNotificationCount = response.data
                    ? parseInt(response.data, 10)
                    : response;
            }

            function setTotalNotificationCount () {
                vm.count = vm.csrNotificationCount + vm.internalNotificationCount;
            }
        }

        function loadNotifications (userId) {
            if (vm.loading) {
                return; // Return early if notifications are already being fetched
            }
            vm.loading = true;

            return followedUsers
                .getUserIdsCSVList(userId)
                .then(getLog)
                .then(setLog)
                .then(getInternalNotifications)
                .then(setInternalNotifications)
                .catch(displayError)
                .finally(disableLoadingState);

            function getLog (userIdsCSVList) {
                return CollaterateSecurityService.hasPermission(secureEntities.ORDER_STATUS, permissions.READ)
                    ? OrderLogService.getOrderLogForCsrUserIds(userIdsCSVList)
                    : [];
            }

            function setLog (response) {
                return vm.orderLogEntries = response;
            }

            function getInternalNotifications () {
                var userIds = vm.watchedUsers.map(function (user) {
                    return user.id;
                });
                userIds.push(vm.userId);
                return CollaterateSecurityService.hasPermission(secureEntities.REST_INTERNAL_NOTIFICATIONS, permissions.READ)
                    ? InternalNotificationService.getNotificationsForRecipientIds(userIds)
                    : [];
            }

            function setInternalNotifications (response) {
                return vm.internalNotifications = response.data && response.data.content
                    ? response.data.content
                    : response;
            }

            function disableLoadingState () {
                vm.loading = false;
            }
        }

        function searchForCSRs (searchText) {
            // looks up users by text entered on PC search box
            return csrService
                .findCsr(searchText)
                .then(unpackResponse)
                .then(removeDuplicates);

            function unpackResponse (response) {
                return UtilService.unpackListResponse(response, 'adminUsers');
            }

            // If a user is already on the watched list, don't include them in search results
            function removeDuplicates (csrs) {
                var filteredList = [];

                if (!vm.watchedUsers.length) {
                    // If there are no watched users, no need to filter list,
                    // just return all the matched csrs
                    return csrs;
                }

                for (var i = 0; i < csrs.length; i++) {
                    for (var j = 0; j < vm.watchedUsers.length; j++) {
                        if (csrs[i].id === vm.watchedUsers[j].id) {
                            break;
                        }

                        if (j + 1 === vm.watchedUsers.length) {
                            filteredList.push(csrs[i]);
                        }
                    }
                }

                return filteredList;
            }
        }

        function toggleNotifications () {
            if (vm.showPanel) {
                close();
            } else {
                // Only update notifications if not currently loading to avoid duplicate calls
                if (!vm.loading) {
                    updateNotifications();
                }
                vm.showPanel = true;
            }
        }

        function close () {
            vm.showPanel = false;
            getNotificationCount(vm.userId);
        }

        function watchUser (user) {
            return followedUsers
                .addUser(vm.userId, user)
                .then(reloadWatchedUsers)
                .then(updateNotifications)
                .then(reloadNotificationCount)
                .catch(displayError);
        }

        function unwatchUser (index) {
            return followedUsers
                .removeUser(vm.userId, index)
                .then(reloadWatchedUsers)
                .then(updateNotifications)
                .then(reloadNotificationCount)
                .catch(displayError);
        }

        function reloadWatchedUsers (response) {
            return loadWatchedUsers(vm.userId);
        }

        function updateNotifications () {
            loadNotifications(vm.userId);
            getNotificationCount(vm.userId);
        }

        function reloadNotificationCount (response) {
            return getNotificationCount(vm.userId);
        }

        function respondToInternalNotification (notification) {
            var newLogEntry = {
                response: notification.response,
                orderId: notification.order.id
            };

            respond(newLogEntry);
            dismissInternalNotification(notification);
        }

        function respond (logEntry) {
            // special handling of html in the response.
            // don't allow user to input angle brackets
            // add html <br> in place of line breaks
            logEntry.response = strBR(strRemoveAngleBrackets(logEntry.response));

            OrderLogService
                .respond(vm.userId, logEntry)
                .then(setGracePeriod)
                .catch(displayError);

            function setGracePeriod () {
                logEntry.inGracePeriod = true;
            }
        }

        function dismissOrderLogEntry (entry) {
            OrderLogService
                .dismissOrderLogEntries(vm.userId, [entry])
                .then(setGracePeriod)
                .then(reloadNotificationCount)
                .catch(displayError);

            function setGracePeriod () {
                entry.inGracePeriod = true;
            }
        }

        function dismissInternalNotification (notification) {
            InternalNotificationService
                .dismissNotification(notification)
                .then(setGracePeriod)
                .then(reloadNotificationCount);

            function setGracePeriod () {
                notification.inGracePeriod = true;
            }
        }

        function openOrderLogEntry (entry) {
            OrderLogService
                .openOrderLogEntries(vm.userId, [entry])
                .then(setGracePeriod)
                .then(reloadNotificationCount)
                .catch(displayError);

            function setGracePeriod () {
                entry.inGracePeriod = false;
            }
        }

        function reopenInternalNotification (notification) {
            InternalNotificationService
                .reopenNotification(notification)
                .then(setGracePeriod)
                .then(reloadNotificationCount)
                .catch(displayError);

            function setGracePeriod () {
                notification.inGracePeriod = false;
            }
        }

        function refreshNotifications () {
            if (vm.showPanel && !vm.loading) {
                getNotificationCount(vm.userId);
                loadNotifications(vm.userId);
            }
        }

        function startNotificationCountPoll () {
            notificationCountTimer = $interval(function () {
            	getNotificationCount(vm.userId);
            }, notificationCountPollingInterval);
        }

        function stopNotificationCountPoll () {
            $interval.cancel(notificationCountTimer);
        }

        function displayError (response) {
            growlNotifications.add(response, 'warning');
        }
    }
}());

